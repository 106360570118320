<template>
    <div>

        <v-form>
                <div v-if="!forgot">
                    <v-btn block outlined @click="$emit('google')">
                        <v-icon class="mr-2" small >{{ mdiGoogle }}</v-icon>
                        Mit Google anmelden
                    </v-btn>
                  <v-btn class="mt-2" block outlined @click="phoneDialog = true">
                    <v-icon class="mr-2" small >{{ mdiPhone }}</v-icon>
                    Mit Handy anmelden
                  </v-btn>
                </div>
                <v-layout justify-center class="my-2" v-if="!forgot">
                    <span class="text--secondary">oder</span>
                </v-layout>
                <div class="pt-4">
                    <div>
                        <v-form v-model="valid" ref="form">
                            <v-text-field
                                    dense
                                    label="E-Mail Adresse"
                                    v-model="email"
                                    validate-on-blur
                                    :rules="[isEmail]"
                                    required
                                    outlined
                            ></v-text-field>
                            <v-text-field
                                    v-if="!forgot"
                                    dense
                                    filled
                                    label="Passwort"
                                    v-model="password"
                                    :append-icon="passwordPlain ? mdiEyeOff : mdiEye"
                                    @click:append="togglePassword"
                                    :type="passwordPlain ? 'text' : 'password'"
                                    :rules="passwordRules"
                                    outlined
                                    required
                            ></v-text-field>
                            <v-text-field
                                    v-if="register"
                                    dense
                                    filled
                                    label="Passwort wiederholen"
                                    v-model="passwordTest"
                                    type="password"
                                    :rules="register? [(pw) => pw === password || 'Die Pw stimmen nicht ueberein']: [() => true]"
                                    outlined
                                    required
                            ></v-text-field>
                            <section v-if="!register">
                                <v-layout justify-space-between align-center v-if="!forgot">
                                    <v-btn @click="submit"
                                           class="blue darken-4 white--text"
                                           :disabled="!valid || password.length < 6" >
                                        Login
                                    </v-btn>
                                    <v-btn text small @click="forgot=true">Passwort vergessen</v-btn>
                                </v-layout>
                                <v-layout v-else justify-space-between align-center>
                                    <v-btn @click="$emit('forgot', {email})">Passwort zurücksetzen</v-btn>
                                    <v-btn color="green lighten-1" text small @click="forgot=false">Zurück</v-btn>
                                </v-layout>
                            </section>
                            <section v-else>
                                <v-btn @click="$emit('register', {email, password})"
                                       class="blue darken-4 white--text"
                                       :disabled="!valid || password.length < 6">
                                    Registrieren
                                </v-btn>
                            </section>
                        </v-form>
                    </div>
                </div>
              <v-overlay
                  :absolute=true
                  :value="loading"
              >
                <v-layout align-center justify-center>
                  <v-progress-circular
                      indeterminate
                      size="30"
                      color="blue"
                  ></v-progress-circular>
                </v-layout>
              </v-overlay>

        </v-form>
      <v-dialog
          :fullscreen="$vuetify.breakpoint.xsOnly"
          v-model="phoneDialog"
          width="500"
          persistent
          eager
      >
        <v-card>
          <v-card-title
              class="headline grey lighten-2"
              primary-title
          >
            Mit Telefonnummer anmelden
            <v-spacer></v-spacer>
            <v-btn @click="phoneDialog = false" icon>
              <v-icon>{{ mdiClose }}</v-icon>
            </v-btn>
          </v-card-title>
          <v-lazy>
            <phone-wizard v-on:successful="phoneDialog = false;$router.replace({ name: 'menu' }); "></phone-wizard>
          </v-lazy>
        </v-card>
      </v-dialog>


    </div>
</template>

<script>
    import PhoneWizard from "./PhoneWizard";
    import { mdiGoogle, mdiPhone, mdiEye , mdiEyeOff ,mdiClose} from '@mdi/js'


    export default {
        name: "LoginForm",
        components: {PhoneWizard},
        props: {
            register: Boolean,
            loading: Boolean,
        },
        data() {
            return {
                mdiGoogle: mdiGoogle,
                mdiPhone: mdiPhone,
                mdiClose: mdiClose,
                mdiEye: mdiEye,
                mdiEyeOff: mdiEyeOff,
                valid: false,
                passwordPlain: false,
                email: '',
                phoneDialog: false,
                password: '',
                passwordRules: [],
                forgot: false,
                passwordTest: ''
            }
        },
        methods: {
            togglePassword() {
                this.passwordPlain = !this.passwordPlain
            },
            isEmail(mail) {
                const mailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return mailRegex.test(mail) || 'Die E-Mail ist nicht gültig!'
            },
            submit() {
                const {email, password} = this
                this.$emit('emailandpassword', ({email, password}))
            }
        },
    }
</script>

<style>
</style>